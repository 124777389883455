import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';

export default function usePropertyFeatureList() {
  // Use toast
  const toast = useToast();

  const refPropertyFeatureListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'الرقم التسلسلي',
      formatter: title,
      sortable: true,
    },
    {
      key: 'display_name',
      label: 'إسم الخاصية',
      formatter: title,
      sortable: true,
    },
    // {
    //   key: 'name',
    //   label: 'معرّف الخاصية',
    //   sortable: true,
    // },
    // {
    //   key: 'value_type',
    //   label: 'نوع الخاصية',
    //   sortable: true,
    // },
    { key: 'actions', label: 'الإجراءات' },
  ];
  const perPage = ref(10);
  const totalPropertyFeature = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refPropertyFeatureListTable.value ? refPropertyFeatureListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPropertyFeature.value,
    };
  });

  const refetchData = () => {
    refPropertyFeatureListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchPropertyFeatures = (ctx, callback) => {
    const { id } = router.currentRoute.params;
    store
      .dispatch('propertyFeature/fetchPropertyFeatures', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        group: id,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { data: propertyFeatures, total } = response.data;

        callback(propertyFeatures);
        totalPropertyFeature.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPropertyFeatures,
    tableColumns,
    perPage,
    currentPage,
    totalPropertyFeature,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPropertyFeatureListTable,
    refetchData,

    // Extra Filters
  };
}
